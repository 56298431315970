import {
  Box, Heading, Text,
} from '@chakra-ui/react';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import LandingLayout from '../components/layouts/landing';
import NextChakraLink from '../components/NextChakraLink/NextChakraLink';

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

function Custom404() {
  const { t } = useTranslation('common');

  return (
    <LandingLayout>
      <Head>
        <title>{`404 | ${t('natural-history-museum-denmark')}`}</title>
      </Head>
      <Box
        maxW="3xl"
        fontSize={{ base: 'md', md: 'lg' }}
        fontWeight="600"
        mt={16}
        mx="auto"
        pb={16}
      >
        <Heading>404</Heading>
        <Text>{t('page-not-found')}. <NextChakraLink href="/" textDecoration="underline">{t('please-try-the-homepage')}</NextChakraLink>.</Text>
      </Box>
    </LandingLayout>
  );
}

export default Custom404;
