import {
  Box, Grid, GridItem,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function LandingLayout({ children, heroComponent, leftSidebar }) {
  return <>
    <Header />
    {/* Ensure page is always a minimum of 100vh: 94px (header) + 368px (footer) = 462px */}
    <Box
      as="main"
      minHeight="calc(100vh - 462px)"
      maxW="2000px"
      mx="auto"
      className="page-border"
    >
      {heroComponent}
      <Grid
        templateColumns="repeat(10, 1fr)"
        gap={4}
      >
        {leftSidebar && (
          <GridItem
            px="4"
            rowSpan={[1]}
            colSpan={[10, null, 4, 3, 2]}
            mt={[8, null, '168px']}
          >
            {leftSidebar}
          </GridItem>
        )}
        <GridItem
          px="4"
          rowSpan={1}
          colSpan={leftSidebar ? [10, null, 6, 7, 8] : 10}
          w="100%"
          maxW={leftSidebar ? null : '1280px'}
          mx={!leftSidebar && 'auto'}
        >
          {children}
        </GridItem>
      </Grid>
    </Box>
    <Footer />
  </>
}

LandingLayout.propTypes = {
  children: PropTypes.node,
  heroComponent: PropTypes.node,
  leftSidebar: PropTypes.node,
};

LandingLayout.defaultProps = {
  children: undefined,
  heroComponent: undefined,
  leftSidebar: undefined,
};

export default LandingLayout;